// Import our CSS
import styles from '../../css/intelligence/app.scss';
import 'chartjs-plugin-colorschemes';
import Vue from 'vue'

window.Vue = require('vue');

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: "#app",

        components: {
            'mobile-menu'           : () => import(/* webpackChunkName: "mobilemenu" */ '../../vue/intelligence/MobileMenu.vue'),
            'form-fundraising'      : () => import(/* webpackChunkName: "form-fundraising" */ '../../../cms/modules/intelligencemodule/src/vue/FormFundraising.vue'),
            'form-fund-commitment'  : () => import(/* webpackChunkName: "form-fund-commitment" */ '../../../cms/modules/intelligencemodule/src/vue/FormFundCommitment.vue'),
            'form-investment'       : () => import(/* webpackChunkName: "form-investment" */ '../../../cms/modules/intelligencemodule/src/vue/FormInvestment.vue'),
            'form-exit'             : () => import(/* webpackChunkName: "form-exit" */ '../../../cms/modules/intelligencemodule/src/vue/FormExit.vue'),
            'form-search'           : () => import(/* webpackChunkName: "form-search" */ '../../../cms/modules/intelligencemodule/src/vue/FormSearch.vue'),
            'form-search-investors' : () => import(/* webpackChunkName: "form-search-investors" */ '../../../cms/modules/intelligencemodule/src/vue/FormSearchInvestors.vue'),
            'impersonate-member'    : () => import(/* webpackChunkName: "impersonate-member" */ '../../../cms/modules/intelligencemodule/src/vue/ImpersonateMember.vue'),
            'form-company-search'   : () => import(/* webpackChunkName: "form-companysearch" */ '../../../cms/modules/intelligencemodule/src/vue/FormCompanySearch.vue'),
            'chart-doughnut'        : () => import(/* webpackChunkName: "chart-doughnut" */ '../../../cms/modules/intelligencemodule/src/vue/ChartDoughnut.vue'),
            'chart-map'             : () => import(/* webpackChunkName: "chart-map" */ '../../../cms/modules/intelligencemodule/src/vue/ChartMap.vue'),
        },

        data: {
            showRetrievalModal: false
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 50 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }
            },

            showMore( parentElement ) {
                let trList  = document.querySelectorAll('table#' + parentElement + 'Table tr.is-hidden')
                let trArray = [...trList];
                trArray.forEach(tr => {
                    tr.classList.remove('is-hidden')
                });

                document.querySelector('a#' + parentElement + 'More').classList.add('is-hidden')
            },

            openModal( modalRef ) {
                this.$refs[modalRef].classList.add( 'is-active' );
                this.$refs[modalRef].focus();
            },

            closeModal( modalRef ) {
                this.$refs[modalRef].classList.remove( 'is-active' );
            }
        },

        created () {
            document.body.classList.add("js-loaded");
            window.addEventListener('scroll', this.handleScroll);
            setTimeout(() => document.body.classList.add( window.pageYOffset >= 10 ? "scrolled" : "notempty" ), 1000);
        },

        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    });

    return vm;
};

// Execute async function
main().then( (vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
